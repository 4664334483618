@import '../../variables';

$border: rgba(255, 255, 255, 0.1) 2px solid;

.todolist-wrapper{
    overflow-y: hidden;
    display: grid;
    grid-template-columns: 0.25fr 1fr;
    overflow-x: hidden;
    height: 100vh;
    width: 100%;
}

.todolist-categories{
    border-right: $border;
    z-index: 3001;
    box-shadow: 5px 0px 0px 0px rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 100%;
    color: white;
    font-family: 'Source Sans Pro';
    background-color: $light_black;
    display: grid;
    grid-template-rows: 0.25fr 1.6fr
}

.todolist-title{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: auto;
    height: auto;
    color: $grey;
    font-size: 2em;
    text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.4);
    
    input{
        font-family: 'Source Sans Pro';
        width: 70%;
        text-align: center;
        font-size: 1em;
        height: 50px;
        border: none;
        outline: none;
        background-color: rgba(255, 255, 255, 0.001);
        color: rgba(255, 255, 255, 0.61);
    }

    input::placeholder{
        font-family: 'Source Sans Pro';
        font-size: 0.95em;
        font-weight: 700;
    }
    
    .MuiSvgIcon-root{
        width: 1.7em;
        height: 1.7em;
        transition: all 200ms;
        cursor: pointer;
        margin-right: 8px;
    }

    .MuiSvgIcon-root:hover{
        color: $bright_red;
    }
}

.todolist-list{
    height: 100%;
    width: 100%;
    background-color: $black;
    display: grid;
    align-items: center;
    grid-template-rows: 10vh 75vh 15vh;
}

.todolist-listcategory{
    color: white;
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-size: 3em;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding: 20px;
    z-index: 2000;
    padding-left: 30px;
    background-image: linear-gradient(#C3073F, #950740, #6F2232);
    border-bottom: $border;
    background-repeat: no-repeat;
    background-attachment: fixed;
    text-shadow: 0px 5px 0px rgba(0, 0, 0, 0.4);
    .MuiSvgIcon-root{
        justify-self: right;
        width: 1.7em;
        height: 1.7em;
        transition: all 200ms;
        cursor: pointer;
        margin-right: 20px;
    }

    .MuiSvgIcon-root:hover{
        color: $black;
    }
}

.todolist-category-text{
    justify-self: left;
}

.todolist-edit-side{
    background-color: $darker_black;
    border-left: $border;
    padding-top: 5%;
    padding-right: 20px;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.1fr 1.5fr;

    .MuiSvgIcon-root{
        color: $grey;
        width: 1.7em;
        height: 1.7em;
        transition: all 200ms;
        cursor: pointer;
        margin-right: 8px;
        margin-bottom: 5%;
    }

    .MuiSvgIcon-root:hover{
        color: $bright_red;
    }

    input{
        margin-bottom: 10px;
        padding: 20px;
        font-family: 'Source Sans Pro';
        background-color: rgba(255, 255, 255, 0.2);;
        opacity: (0.8);
        font-size: 1.1em;
        color: white;
        width: 87%;
        outline: none;
        font-weight: 500;
        border: none;
        text-align: left;
    }

    input::placeholder{
        font-family: 'Source Sans Pro';
        font-size: 0.95em;
        font-weight: 700;
    }

    textarea{
        margin-bottom: 10px;
        padding: 20px;
        font-family: 'Source Sans Pro';
        background-color: rgba(255, 255, 255, 0.2);;
        opacity: (0.8);
        font-size: 1.1em;
        color: white;
        width: 87%;
        outline: none;
        font-weight: 500;
        border: none;
        text-align: left;
        resize: none;
    }
    textarea::placeholder{
        font-family: 'Source Sans Pro';
        font-size: 0.95em;
        font-weight: 700;
    }

    button{
        font-family: 'Source Sans Pro';
        height: 50px;
        font-weight: 700;
        border: none;
        color: white;
        font-size: 1.1em;
        width: 100%;
        background-color: $bright_red;
        outline: none;
        transition: all 300ms;
        cursor: pointer;
    }
}

.todolist-in{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    height: 75vh;
    align-self: start;
}

.todolist-edit{
    z-index: 3000;
    height: 100%;
    width: 100%;
    padding-left: 30px;
    padding-right: 20px;
    border-top: $border;
    display: grid;
    grid-template-columns: 0.3fr 0.9fr 0.15fr;
    background-color: $darker_black;
    align-content: center;
    input{
        padding: 20px;
        font-family: 'Source Sans Pro';
        background-color: rgba(255, 255, 255, 0.2);;
        opacity: (0.8);
        font-size: 1.7em;
        color: white;
        width: 87%;
        outline: none;
        font-weight: 500;
        border: none;
        text-align: left;
    }

    input::placeholder{
        font-family: 'Source Sans Pro';
        font-size: 0.95em;
        font-weight: 700;
    }

    .MuiSvgIcon-root{
        align-self: center;
        justify-self: start;
        width: 3em;
        height: 3em;
        color: $grey;
        transition: all 200ms;
        cursor: pointer;
        margin-right: 8px;
    }

    .MuiSvgIcon-root:hover{
        color: $bright_red;
    }
}

.loading-circle{
    color: $bright_red !important;
    height: 90px !important;
    width: 90px !important;
}

.loading-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    font-family: 'Source Sans Pro';
    font-weight: 900;
    font-size: 3em;
    height: 100vh;
    width: 100%;
    background-color: $light_black;
}

.todolist-category{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 2em;
    height: 75px;

    .MuiSvgIcon-root{
        height: 1.3em;
        width: 1.3em;
    }

    span{
        padding-left: 20px;
    }
    
}

.todolist-category-out:hover{
    background-color: rgba(255, 255, 255, 0.1);
    .todolist-side{
        background-color: $bright_red;
        transition: all 200ms;
    }
}

.todolist-edit-out{
    padding: auto;
    height: 100%;
}

.todolist-side{
    width: 10px;
    justify-self: flex-end;
    height: 100%;
    background-color: $granate_pale;
    transition: all 200ms;
}

.todolist-side-active{
    width: 10px;
    justify-self: flex-end;
    height: 100%;
    background-color: $bright_red;
    transition: all 200ms;
}

.todolist-category-out{
    z-index: 3050;
    margin-top: 6px;
    margin-bottom: 4px;
    transition: all 200ms;
    cursor: pointer;
    display: grid;
    width: 100%;
    grid-template-columns: 2.5fr 0.01fr;
}

.active{
    background-color: rgba(255, 255, 255, 0.1);
}

.todo-list-adder{
    height: 11vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 5px 0px 0px rgba(0, 0, 0, 0.2);
}

.todolist-category-cover{
    height: 89vh;
    overflow-y: auto;
}

.todolist-category-cross:hover{
    color: $bright_red;
    transition: all 200ms;
}

.todolist-category-cross{
    z-index: 6000;
    transition: all 200ms;
}
@import '../../../../variables';
.login-wrapper{
    height: 100%;
    display: grid;
    grid-template-rows: 0.2fr 1fr;
    height: 400px;
    width: 450px;
}

.login-form-wrapper{
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-rows: 0.5fr 0.5fr 0.1fr 0.4fr;
}

.login-form-button{
    font-family: 'Source Sans Pro';
    height: 50px;
    font-weight: 700;
    border: none;
    color: white;
    font-size: 0.6em;
    width: 80%;
    background-color: $bright_red;
    outline: none;
    transition: all 300ms;
    cursor: pointer;
}

.login-form-button:hover{
    background-color: $purple;
}

.login-title{
    font-weight: 900;
    font-size: 1.2em;
    color: white;
}

.login-error-message{
    font-size: 0.6em;
    color: $bright_red;
}
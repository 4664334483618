@import '../../variables';
.home-wrapper{
    min-height: 100vh;
    height: auto;
    display: grid;
    background-image: linear-gradient(#C3073F, #950740, #6F2232);
    grid-template-rows: 0.28fr 1fr;
}

.home-card{
    justify-self: center;
    align-self: start;
    display: grid;
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.4);
    grid-template-rows: 4.5fr 1fr;
    background-color: $light_black;
    font-weight: 400;
    font-size: 2em;
    padding: 1em;
    input{
        background-color: $grey;
        opacity: (0.8);
        height: 2em;
        max-width: 80%;
        outline: none;
        border: none;
        text-align: center;
        font-size: 1em;
    }
}

.home-switchtext{
    align-self: flex-end;
    font-size: 0.5em;
    color: rgb(82, 82, 82);
    span{
        cursor: pointer;
        text-decoration: underline;
    }
}

.home-title{
    margin-bottom: 20px;
    font-size: 5em;
    text-shadow: 0px 5px 0px rgba(0, 0, 0, 0.4);
    font-weight: 900;
    color: white;
    align-self: flex-end;
}